import React, {Component} from "react";
import {MenuItems} from './Data/menuStructure.js';
import {Route, Link} from 'react-router-dom';

export class TopBar extends Component {
    render() {
        return (
            <div className="TopBar">
                <a href="/#">Liesbeth's Pages</a>
                <img src="/icons/laptop.svg" alt="icon"/>
            </div>
        );
    }
}

export class SideBar extends Component {
    constructor(props) {
        super(props);
        this.menuExpand = this.menuExpand.bind(this);
        this.menuSelectionClick = this.menuSelectionClick.bind(this);
    }
    menuExpand(b){
        this.props.liftState({MenuExpansion: b});
        if(b !== true){
            this.props.liftState({MenuSelection: MenuItems[0].name, MenuItemSelection: MenuItems[0].startpage});
        }
    }
    menuSelectionClick(item){
        this.props.liftState({MenuSelection: item.name, MenuItemSelection: item.startpage});
        this.menuExpand(true);
    }

    render() {
        const bool = this.props.MenuExpansion;
        let elements = [];
        MenuItems.forEach(ParseObjectsFunction.bind(this));
        function ParseObjectsFunction(value) {
            if(bool && value.name === MenuItems[0].name){       //1e menu items
                let func = function(){this.menuExpand(false)}.bind(this);
                let elem = React.createElement('img', { key: 'img_' + value.name, src:`/icons/${value.iconleft}`, alt: value.label, title: value.label });
                let path = `/${value.name}`;
                if(!this.props.routes.has(path)){
                    this.props.routes.set(path, <Route exact path={path} key={value.name} component={value.startpage} />);
                }
                elements.push(<Link to={`/${value.name}`} key={value.name} onClick={func}>{elem}</Link>)
            }else if (value.name === MenuItems[0].name){        //2e menu items
                let func = function(){this.menuExpand(true)}.bind(this);
                let elem = React.createElement('img', { key: 'img_' + value.name, src:`/icons/${value.iconright}`, alt: value.label, title: value.label });
                let path = `/${value.name}`;
                if(!this.props.routes.has(path)){
                    this.props.routes.set(path, <Route exact path={path} key={value.name} component={value.startpage} />);
                }
                elements.push(<Link to={`/${value.name}`} key={value.name} onClick={func}>{elem}</Link>);
            }else{
                let func = function(){this.menuSelectionClick(value)}.bind(this);
                let elem = React.createElement('img', { key: 'img_' + value.name, src:`/icons/${value.icon}`, alt: value.label, title: value.label });
                let path = `/${value.name}`;
                if(!this.props.routes.has(path)){
                    this.props.routes.set(path, <Route exact path={path} key={value.name} component={value.startpage} />);
                }
                elements.push(<Link to={`/${value.name}`} key={value.name} onClick={func}>{elem}</Link>);
            }
        }
        return (
            React.createElement('div', {className : 'SideBar'}, elements)
        );
    }
}

export class SideBarExpanded extends Component {

    handleMenuActionClick (action) {
        //console.log(action.name);
        this.props.liftState({MenuItemSelection: action.name});
       if(this.props.MenuSelection === MenuItems[0].name){
            this.props.liftState({MenuSelection: action.name, MenuItemSelection: action.startpage});
        }else{
            this.props.liftState({MenuExpansion: false});
        }
    }

    render(){
        const selected = this.props.MenuSelection;
        let object = MenuItems.find(FindObjectFunction.bind(this));
        function FindObjectFunction(value) {
            return value.name === selected;
        }

        let elements = [];
        if(selected===MenuItems[0].name){
            MenuItems.forEach(ParseObjectsFunction.bind(this));
        }else{
            object.labels.forEach(ParseObjectsFunction.bind(this));
        }
        
        function ParseObjectsFunction(value) {
            const ActionClick = function() { this.handleMenuActionClick(value)}.bind(this);
            let elem = React.createElement('p', {key: value.name}, value.label);
            if(selected===MenuItems[0].name) {
                let path = `/${value.name}`;
                elements.push(<Link to={path} key={value.name} onClick={ActionClick}>{elem}</Link>);
                if(!this.props.routes.has(path)){
                    this.props.routes.set(path, <Route path={path} key={value.name} component={value.startpage} />);
                }
            }else{
                let path = `/${selected}/${value.name}`;
                elements.push(<Link to={path} key={value.name} onClick={ActionClick}>{elem}</Link>);
                if(!this.props.routes.has(path)){
                    if(value.site === "picturePage"){
                        this.props.routes.set(path, <Route path={path} key={value.name} component={value.site} text={value.text} title={value.title} collection={value.collection}/>);
                    }else if(value.site === "travelPage"){
                        this.props.routes.set(path, <Route path={path} key={value.name} component={value.site} text={value.text} title={value.title} collection={value.collection} route={value.route} center={value.center}/>);
                    }else{
                        this.props.routes.set(path, <Route path={path} key={value.name} component={value.site}/>);
                    }
                }
            }
        }
        return (React.createElement('div', {className : 'SideInfo'}, elements))
    }
}

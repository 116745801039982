import React, {Component} from "react";

export class OtherProjects extends Component {
    render(){
        return(
            <div className="website">
                <div >
                    <h1>IBC Chicken House</h1>
                    <p>Chicken house build from an IBC Container</p>
                </div>
                <div>
                    <img src="/photos/projects/Chickens_01.jpg" alt="Chickens"/>
                    <img src="/photos/projects/Chickens_02.jpg" alt="Chickens"/>
                    <img src="/photos/projects/Chickens_03.jpg" alt="Chickens"/>
                </div>
            </div>
        );
    }
}
import React, {Component} from "react";
import ArcGISMap from "./ArcGISmap";

export class TravelStartPage extends Component {
    render(){
        return(
            <div className="travelpage">
                <h1>My travels</h1>
                <p>I love to travel ands hope to see more of this beautiful world!</p>
                <p> (In <mark className="blue">blue</mark> are all the countries and states I have been to.</p>
                <p> The countries in <mark className="red">red</mark> are on the top of my to-go list)</p>
                <div className="travelMap">
                    <ArcGISMap/>
                </div>
            </div>
        );
    }
}

import React, {Component} from "react";

export class Website extends Component {
    render(){
        return(
            <div className="website">
                <div>
                    <div>
                        <h2>My old page</h2>
                        <p>Made many years ago with Durpal</p>
                        <img src="/icons/drupal.svg" alt="icon" className="icon"/>
                    </div>
                    <div>
                        <img src="/photos/projects/OldSite.jpg" alt="site1"/>
                        <img src="/photos/projects/OldSite2.jpg" alt="site1"/>
                        <img src="/photos/projects/OldSite3.jpg" alt="site1"/>
                    </div>
                </div>
                <div>
                    <div>
                        <h2>Websites for university projects</h2>
                    </div>
                    <div>
                        <img src="/photos/projects/OldSiteProject.jpg" alt="kuleuven"/>
                        <img src="/photos/projects/OldSiteProject2.jpg" alt="ugent"/>
                        <img src="/photos/projects/OldSiteProject3.jpg" alt="ugent"/>
                    </div>
                </div>
                <div>
                    <div>
                        <h2>This website</h2>
                        <p> Build in React JS</p>
                        <img src="/icons/react.svg" alt="icon" className="icon"/>
                    </div>
                    <div>
                        <img src="/photos/projects/Site.jpg" alt="site"/>
                    </div>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import './App.css';
import {TopBar, SideBar, SideBarExpanded} from './Menu.js';
//import {Content} from './Content.js'
import {MenuItems} from './Data/menuStructure.js'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {ErrorPage} from "./content/Error";
import {GalleryTemplate} from "./GalleryTemplate";
import {TravelTemplate} from "./TravelTemplate";
import ScrollToTop from "react-scroll-to-top";

class App extends Component{
    constructor(props) {
        super(props);
        this.state = {
            MenuExpansion: false,
            MenuSelection: MenuItems[0].name,
            MenuItemSelection: MenuItems[0].startpage,
            routes: new Map()
        };
    }

    liftState = state => {
        this.setState(state);
    };

    render() {
        this.state.routes.set('Home', <Route  exact path={"/"} key={'Home'} component={MenuItems[0].startpage} />);

        return (
            <div className="App">
                <ScrollToTop smooth color="#383e49" />
                <TopBar/>
                <div className="Container">
                    <Router>
                        <SideBar MenuExpansion={this.state.MenuExpansion}
                                      MenuSelection={this.state.MenuSelection}
                                      MenuItemSelection={this.state.MenuItemSelection}
                                      routes={this.state.routes}
                                      liftState={this.liftState}/>
                        {this.state.MenuExpansion ? <SideBarExpanded MenuSelection={this.state.MenuSelection}
                                                                     MenuItemSelection={this.state.MenuSelection}
                                                                     MenuExpansion={this.state.MenuExpansion}
                                                                     routes={this.state.routes}
                                                                     liftState={this.liftState}/> : null}
                        <Routes routes={this.state.routes}/>
                    </Router>

                </div>
                <footer className="footer"> © by Liesbeth Bogaert, 2021 </footer>
            </div>
    );
  }
}

/*
<Content MenuSelection={this.state.MenuSelection}
                             MenuItemSelection={this.state.MenuItemSelection}
                             routes={this.state.routes}/>
 */

export default App;

class Routes extends Component{

    render(){
        let routes = [];
        for(let[key, value] of this.props.routes){
           if(typeof value.props.component === 'string'){
               if(value.props.component === 'picturePage'){
                   routes.push(<Route exact path={key} key={key} component={()=><GalleryTemplate collection={value.props.collection} text={value.props.text} title={value.props.title}/>} />);
               }else if(value.props.component === 'travelPage'){
                   routes.push(<Route exact path={key} key={key} component={()=><TravelTemplate collection={value.props.collection} text={value.props.text} title={value.props.title} route={value.props.route} center={value.props.center}/>} />);
               }else{
                   routes.push(<Route exact path={key} key={key} component={ErrorPage} />);
               }
            }else {
                routes.push(value);
            }
        }
        return(React.createElement('div',{className : 'Content'}, routes));
    }

}
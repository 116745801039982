const Molen = [
    {
        src: '/photos/photos/Molen/Website035.jpg',
        alt: 'Molen',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Molen/Website034.jpg',
        alt: 'Molen',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Molen/Website037.jpg',
        alt: 'molen',
        width: 3,
        height: 4
    },
    {
        src: '/photos/photos/Molen/Website033.jpg',
        alt: 'molen',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Molen/Website036.jpg',
        alt: 'molen',
        width: 3,
        height: 4
    },
    {
        src: '/photos/photos/Molen/Website038.jpg',
        alt: 'molen',
        width: 4,
        height: 3
    }
];

const Zwin = [
    {
        src: '/photos/photos/Zwin/Website011.jpg',
        alt: 'Zwin1',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Zwin/Website012.jpg',
        alt: 'Zwin2',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Zwin/Website013.jpg',
        alt: 'Zwin3',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Zwin/Website015.jpg',
        alt: 'Zwin4',
        width: 4,
        height: 3
    },
];

const Schelde = [
    {
        src: '/photos/photos/Schelde/Website002.jpg',
        alt: 'Schelde',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Schelde/Website003.jpg',
        alt: 'Schelde',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Schelde/Website005.jpg',
        alt: 'Schelde',
        width: 3,
        height: 4
    },
    {
        src: '/photos/photos/Schelde/Website004.jpg',
        alt: 'Schelde',
        width: 4,
        height: 3
    },
    {
        src: '/photos/photos/Schelde/Website006.jpg',
        alt: 'Schelde',
        width: 3,
        height: 4
    },
];

const Rhein20 = [
    {
        src: '/photos/travel/Rhein20/rhein_001.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_002.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_003.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_004.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_005.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_006.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_007.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_008.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_009.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_010.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_011.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_012.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_013.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_014.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_015.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_016.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_017.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_018.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_019.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_020.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_021.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_022.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_023.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_024.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_025.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_026.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_027.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_028.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_029.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_030.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_031.jpg',
        alt: 'Mittelrhein',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Rhein20/rhein_032.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_033.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_034.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_035.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Rhein20/rhein_036.jpg',
        alt: 'Mittelrhein',
        width: 4,
        height: 3
    }
];

const Uzbekistan19 = [
    {
        src: '/photos/travel/Uzbekistan/uzbekistan001.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan002.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan003.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan004.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan005.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan006.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan007.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan008.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan009.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan010.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan011.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan012.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan013.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan014.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan015.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan016.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan017.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan018.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan019.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan020.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan021.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan022.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan023.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan024.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan025.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan026.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan027.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan028.jpg',
        alt: 'Uzbekistan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan029.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Uzbekistan/uzbekistan030.jpg',
        alt: 'Uzbekistan',
        width: 4,
        height: 3
    }



];

const Japan18 = [
    {
        src: '/photos/travel/Japan/japan001.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan002.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan003.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan004.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan005.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan006.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan007.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan008.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan009.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan010.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan011.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan012.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan013.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan014.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan015.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan016.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan017.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan018.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan019.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan020.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan021.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan022.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan023.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan024.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan025.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan026.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan027.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan028.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan029.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan030.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan031.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan032.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan033.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan034.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan035.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan036.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan037.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan038.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan039.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },{
        src: '/photos/travel/Japan/japan040.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan041.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan042.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan043.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan044.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan045.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan046.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan047.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan048.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan049.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan050.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan051.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan052.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan053.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan054.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan055.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan056.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan057.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan058.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan059.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan060.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan061.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan062.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan064.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Japan/japan065.jpg',
        alt: 'Japan',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Japan/japan066.jpg',
        alt: 'Japan',
        width: 4,
        height: 3
    },
];

const Holzgau19 = [
    {
        src: '/photos/travel/Holzgau19/holzgau19_001.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_002.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_003.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_004.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_005.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_006.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_007.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_008.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_013.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_010.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_011.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_012.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_009.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau19/holzgau19_014.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    }
];

const Holzgau18 = [
    {
        src: '/photos/travel/Holzgau18/holzgau18_001.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_002.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_003.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_005.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_004.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_006.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_007.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_008.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_009.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_010.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_011.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_012.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_013.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
        {
        src: '/photos/travel/Holzgau18/holzgau18_014.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_029.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_015.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_016.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_017.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_018.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_030.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_019.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_020.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_021.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_022.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_023.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_031.jpg',
        alt: 'Holzgau',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_024.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_025.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_026.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_027.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Holzgau18/holzgau18_028.jpg',
        alt: 'Holzgau',
        width: 4,
        height: 3
    },

]

const UsaCan17 = [
    {
        src: '/photos/travel/USA17/usa17_001.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_002.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_003.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_079.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_004.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_005.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_006.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_007.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_008.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_009.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_010.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_011.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_012.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_013.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_014.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_015.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_016.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_080.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_017.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_018.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_019.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_020.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_021.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_081.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_022.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_023.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_024.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_025.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_026.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_082.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_027.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_028.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_029.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_030.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_031.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_032.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_033.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_034.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_035.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_036.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_037.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_038.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_083.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_039.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_040.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_041.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_042.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_043.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_044.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_045.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_046.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_047.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_048.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_049.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_050.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_084.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_051.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_052.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_053.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_085.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_054.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_055.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_056.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_057.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_058.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_086.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_059.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_060.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_061.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_062.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_063.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_064.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_065.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_066.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_067.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_068.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_069.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_070.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_071.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_072.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_073.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_074.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_075.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_076.jpg',
        alt: 'usa-can',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/USA17/usa17_077.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/USA17/usa17_078.jpg',
        alt: 'usa-can',
        width: 4,
        height: 3
    }

]

const Paris18 = [
    {
        src: '/photos/travel/Paris18/paris18_001.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_002.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_003.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_004.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_005.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_006.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_007.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_008.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_009.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_010.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_011.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_012.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_013.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_014.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_015.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_016.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_017.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_018.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_019.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_020.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_021.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_022.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_023.jpg',
        alt: 'paris',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Paris18/paris18_024.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Paris18/paris18_025.jpg',
        alt: 'paris',
        width: 4,
        height: 3
    },

]

const ToulouseAndorra = [
    {
        src: '/photos/travel/Toulouse/toulouse_001.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_002.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_003.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_004.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_005.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_006.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_007.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_008.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_009.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_010.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_011.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_017.jpg',
        alt: 'toulouse',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Toulouse/toulouse_012.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_013.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_014.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_016.jpg',
        alt: 'toulouse',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Toulouse/toulouse_015.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_028.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_018.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_019.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_020.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_021.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_024.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_025.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_026.jpg',
        alt: 'toulouse',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Toulouse/toulouse_027.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    },
    {
        src: '/photos/travel/Toulouse/toulouse_023.jpg',
        alt: 'toulouse',
        width: 3,
        height: 4
    },
    {
        src: '/photos/travel/Toulouse/toulouse_022.jpg',
        alt: 'toulouse',
        width: 4,
        height: 3
    }

]

export {Schelde, Zwin, Molen, Japan18, Uzbekistan19, Holzgau19, Holzgau18, Rhein20, UsaCan17, Paris18, ToulouseAndorra};

import React, {Component} from "react";
import Gallery from "react-photo-gallery";
import {loadModules} from "esri-loader";

export class TravelTemplate extends Component {

    loadRoute(graphicsLayer, Graphic){
        let pad = [];
        this.props.route.forEach(ParseObjectsFunction.bind(this));
        function ParseObjectsFunction(punt) {
            pad.push([punt.longitude, punt.latitude]);
            let point = {
                type: "point",
                longitude: punt.longitude,
                latitude: punt.latitude
            };
            let simpleMarkerSymbol = {
                type: "simple-marker",
                color: [0, 0, 255],
                outline: {
                    color: [255, 255, 255],
                    width: 1
                }
            };
            let pointGraphic = new Graphic({
                geometry: point,
                symbol: simpleMarkerSymbol
            });
            graphicsLayer.add(pointGraphic);
        }
        let simpleLineSymbol = {
            type: "simple-line",
            color: [0, 0, 255],
            width: 2
        };
        let polyline = {
            type: "polyline",
            paths: pad
        };
        let polylineGraphic = new Graphic({
            geometry: polyline,
            symbol: simpleLineSymbol
        });
        graphicsLayer.add(polylineGraphic);
    }

    componentDidMount() {
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(['esri/Map', 'esri/views/MapView', 'esri/Graphic', 'esri/layers/GraphicsLayer'], { css: true })
            .then(([ArcGISMap, MapView, Graphic, GraphicsLayer]) => {
                const map = new ArcGISMap({
                    basemap: 'topo-vector'
                });
                let graphicsLayer = new GraphicsLayer();
                map.add(graphicsLayer);
                this.view = new MapView({
                    container: 'viewDiv',
                    map: map,
                    center: this.props.center,
                    zoom:5
                });
                this.loadRoute(graphicsLayer, Graphic);
            });
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }
    render() {
        return (
            <div>
                <h1>{this.props.title}</h1>
                <p>{this.props.text}</p>
                <div className="miniMap"  id="viewDiv"/>
                <Gallery photos={this.props.collection} limitNodeSearch={3} />
            </div>
        )
    }
}

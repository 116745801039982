import React from 'react';
import { loadModules } from 'esri-loader';
import {Beenpoints, Wantpoints} from "../Data/MapPoints";

export class ArcGISMap extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            center: [0, 40],
            zoom: 3
        }
    }

    loadBeenPoints(graphicsLayer, Graphic){
        Beenpoints.forEach(ParseObjectsFunction.bind(this));
        function ParseObjectsFunction(punt) {
            let point = {
                type: "point",
                longitude: punt.longitude,
                latitude: punt.latitude
            };
            let simpleMarkerSymbol = {
                type: "simple-marker",
                color: [0, 0, 255],
                outline: {
                    color: [255, 255, 255],
                    width: 1
                }
            };
            let attr = {
                Location: punt.country,
                urls: ""
            };
            if( punt.been ){
                punt.been.forEach( jaar => {
                    if(jaar.url){
                        attr.urls += ("<a target='_blank' href=" + jaar.url + ">" + jaar.place + " " + jaar.year + "</a>")
                    }else{
                        attr.urls += ("<p>" + jaar.place + " " + jaar.year + "</p>")
                    }
                });
            }
            let pointGraphic = new Graphic({
                geometry: point,
                symbol: simpleMarkerSymbol,
                attributes: attr,
                popupTemplate: {
                    title: "Been: {Location}",
                    content: "{urls}"
                }
            });


            graphicsLayer.add(pointGraphic);
        }
    }

    loadWantPoints(graphicsLayer, Graphic){
        Wantpoints.forEach(ParseObjectsFunction.bind(this));
        function ParseObjectsFunction(punt) {
            let point = {
                type: "point",
                longitude: punt.longitude,
                latitude: punt.latitude
            };
            let simpleMarkerSymbol = {
                type: "simple-marker",
                color: [255, 0, 0],
                outline: {
                    color: [255, 255, 255],
                    width: 1
                }
            };
            let attr = {
                Location: punt.country,
            };
            let pointGraphic = new Graphic({
                geometry: point,
                symbol: simpleMarkerSymbol,
                attributes: attr,
                popupTemplate: {
                    title: "To Go: {Location}"
                }
            });

            graphicsLayer.add(pointGraphic);
        }
    }

    componentDidMount() {
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(['esri/Map', 'esri/views/MapView', 'esri/Graphic', 'esri/layers/GraphicsLayer'], { css: true })
            .then(([ArcGISMap, MapView, Graphic, GraphicsLayer]) => {
                const map = new ArcGISMap({
                    basemap: 'topo-vector'
                });
                let graphicsLayer = new GraphicsLayer();
                map.add(graphicsLayer);

                this.view = new MapView({
                    container: this.mapRef.current,
                    map: map,
                    center: this.state.center,
                    zoom: this.state.zoom
                });
                this.loadBeenPoints(graphicsLayer, Graphic);
                this.loadWantPoints(graphicsLayer, Graphic);
            });
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    render() {
        return (
            <div className="Map" ref={this.mapRef}/>
        );
    }

}

export default ArcGISMap;
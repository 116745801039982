import React, {Component} from "react";


export class ErrorPage extends Component {
    render(){
        return(
            <div>
                <h1>404 Page not found</h1>
                <p>This page is still under construction!</p>
            </div>
        );
    }
}
import React, {Component} from "react";
import {SocialList} from "./SocialList";

export class InfoStartPage extends Component {
    render(){
        return(
            <div className="infopage">
                <h1>Hi I'm Liesbeth!</h1>
                <p>Some more information about me</p>
                <div className="socialinfo">
                    <SocialList/>
                </div>
            </div>
        );
    }
}
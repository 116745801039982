const Beenpoints = [
    {
        country: 'Belgium',
        longitude: '4.6667145',
        latitude: '50.6402809'
    },
    {
        country: 'Germany',
        longitude: '10.4234469',
        latitude: '51.0834196'
    },
    {
        country: 'Austria',
        longitude: '13.199959',
        latitude: '47.2000338',
        been: [
            {
                place: 'Holzgau',
                url: 'https://bart.bogaert.com/travel/holzgau',
                year: '1998'
            },
            {
                place: 'Austria',
                url: 'https://bart.bogaert.com/travel/alps',
                year: '2009'
            },
            {
                place: 'Holzgau',
                year: '2018'
            },
            {
                place: 'Holzgau',
                year: '2019'
            }
        ]
    },
    {
        country: 'Swiss',
        longitude: '8.2319736',
        latitude: '46.7985624'
    },
    {
        country: 'Japan',
        longitude: '139.2394179',
        latitude: '36.5748441',
        been: [
            {
                place: 'Japan',
                year: '2018'
            }
        ]
    },
    {
        country: 'Uzbekistan',
        longitude: '63.9528098',
        latitude: '41.32373',
        been: [
            {
                place: 'Uzbekistan',
                year: '2019'
            }
        ]
    },
    {
        country: 'Israel',
        longitude: '34.8667654',
        latitude: '31.5313113',
        been: [
            {
                place: 'Jordan and Israel',
                url: "https://bart.bogaert.com/travel/middle_east",
                year: '2013'
            }
        ]
    },
    {
        country: 'Jordan',
        longitude: '36.941628',
        latitude: '31.1667049',
        been: [
            {
                place: 'Jordan and Israel',
                url: "https://bart.bogaert.com/travel/middle_east",
                year: '2013'
            }
        ]
    },
    {
        country: 'Spain',
        longitude: '-4.8380649',
        latitude: '39.3262345',
        been: [
            {
                place: 'Spain',
                url: "https://bart.bogaert.com/travel/spain",
                year: '2007'
            },
            {
                place: 'Camino Ignaciano',
                year: '2013'
            },
            {
                place: 'Spain camping',
                year: '2015'
            },
        ]
    },
    {
        country: 'Portugal',
        longitude: '-7.8896263',
        latitude: '40.0332629',
        been: [
            {
                place: 'Portugal',
                url: "https://bart.bogaert.com/travel/portugal",
                year: '2011'
            },
            {
                place: 'Last-years school trip, Lissabon',
                year: '2015'
            },
        ]
    },
    {
        country: 'Italy',
        longitude: '12.674297',
        latitude: '42.6384261',
        been: [
            {
                place: 'Italy',
                url: "https://bart.bogaert.com/travel/italy",
                year: '2006'
            },
            {
                place: 'Exchange project, Milan',
                year: '2011'
            },
            {
                place: 'Italy',
                url: "https://bart.bogaert.com/travel/italy12",
                year: '2012'
            },
        ]
    },
    {
        country: 'Norway',
        longitude: '25.78621479618954',
        latitude: '71.15138626176739',
        been: [
            {
                place: 'Scandinavia road trip',
                url: "https://bart.bogaert.com/travel/scandinavia",
                year: '2010'
            }
        ]
    },
    {
        country: 'Sweden',
        longitude: '14.5208584',
        latitude: '59.6749712',
        been: [
            {
                place: 'Scandinavia road trip',
                url: "https://bart.bogaert.com/travel/scandinavia",
                year: '2010'
            }
        ]
    },
    {
        country: 'Finland',
        longitude: '25.9209164',
        latitude: '63.2467777',
        been: [
            {
                place: 'Scandinavia road trip',
                url: "https://bart.bogaert.com/travel/scandinavia",
                year: '2010'
            }
        ]
    },
    {
        country: 'Hungary',
        longitude: '19.5060937',
        latitude: '47.1817585',
        been: [
            {
                place: 'Hungary',
                year: '2016'
            }
        ]
    },
    {
        country: 'Netherlands',
        longitude: '5.7480821',
        latitude: '52.5001698'
    },
    {
        country: 'Denmark',
        longitude: '10.3333283',
        latitude: '55.670249',
        been: [
            {
                place: 'Scandinavia road trip',
                url: "https://bart.bogaert.com/travel/scandinavia",
                year: '2010'
            }
        ]
    },
    {
        country: 'France',
        longitude: '1.8883335',
        latitude: '46.603354',
        been: [
            {
                place: 'Normandy',
                url: "https://bart.bogaert.com/travel/normandy",
                year: '2005'
            },
            {
                place: 'Disneyland Paris',
                url: "https://bart.bogaert.com/travel/disneyland",
                year: '2005'
            },
            {
                place: 'Paris',
                year: '2014'
            },
            {
                place: 'South of France',
                year: '2017'
            },
            {
                place: 'Paris',
                year: '2018'
            },
            {
                place: 'Toulouse',
                year: '2018-2019'
            }
        ]
    },
    {
        country: 'Sicily',
        longitude: '14.155048',
        latitude: '37.587794',
        been: [
            {
                place: 'Sicily',
                year: '2015'
            }
        ]
    },
    {
        country: 'Crete',
        longitude: '24.4633207',
        latitude: '35.3084749',
        been: [
            {
                place: 'Crete',
                url: "https://bart.bogaert.com/travel/crete",
                year: '1998'
            }
        ]
    },
    {
        country: 'Andorra',
        longitude: '1.5732033',
        latitude: '42.5407167',
        been: [
            {
                place: 'Andorra',
                year: '2018-2019'
            }
        ]
    },
    {
        country: 'Luxemburg',
        longitude: '6.1296751',
        latitude: '49.8158683'
    },
    {
        country: 'Lichtenstein',
        longitude: '9.5531527',
        latitude: '47.1416307'
    },
    {
        country: 'Illinois, USA',
        longitude: '-89.4337288',
        latitude: '40.0796606',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            }
        ]
    },
    {
        country: 'Utah, USA',
        longitude: '-111.7143584',
        latitude: '39.4225192',
        been: [
            {
                place: 'Arizona, Utah, Nevada, California',
                url: "https://bart.bogaert.com/travel/US_2010",
                year: '2010'
            }
        ]
    },
    {
        country: 'Nevada, USA',
        longitude: '-116.8537227',
        latitude: '39.5158825',
        been: [
            {
                place: 'Arizona, Utah, Nevada, California',
                url: "https://bart.bogaert.com/travel/US_2010",
                year: '2010'
            }
        ]
    },
    {
        country: 'California, USA',
        longitude: '-118.7559974',
        latitude: '36.7014631',
        been: [
            {
                place: 'Arizona, Utah, Nevada, California',
                url: "https://bart.bogaert.com/travel/US_2010",
                year: '2010'
            }
        ]
    },
    {
        country: 'Florida, USA',
        longitude: '-81.4639835',
        latitude: '27.7567667',
        been: [
            {
                place: 'Florida',
                url: "https://bart.bogaert.com/travel/florida",
                year: '2008'
            }
        ]
    },
    {
        country: 'Arizona, USA',
        longitude: '-111.7632755',
        latitude: '34.395342',
        been: [
            {
                place: 'Arizona, Utah, Nevada, California',
                url: "https://bart.bogaert.com/travel/US_2010",
                year: '2010'
            }
        ]
    },
    {
        country: 'Massachusetts, USA',
        longitude: '-72.032366',
        latitude: '42.3788774',
        been: [
            {
                place: 'Boston - Canada',
                year: '2017'
            }
        ]
    },
    {
        country: 'New York, USA',
        longitude: '-75.8449946',
        latitude: '43.1561681',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            },
            {
                place: 'Boston - Canada',
                year: '2017'
            }
        ]
    },
    {
        country: 'Vermont, USA',
        longitude: '-72.5002608',
        latitude: '44.5990718',
        been: [
            {
                place: 'Boston - Canada',
                year: '2017'
            }
        ]
    },
    {
        country: 'New Hampshire, USA',
        longitude: '-71.6553992',
        latitude: '43.4849133',
        been: [
            {
                place: 'Boston - Canada',
                year: '2017'
            }
        ]
    },
    {
        country: 'Maryland, USA',
        longitude: '-76.9382069',
        latitude: '39.5162234',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            }
        ]
    },
    {
        country: 'Pennsylvania, USA',
        longitude: '-77.7278831',
        latitude: '40.9699889',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            }
        ]
    },
    {
        country: 'New Jersey, USA',
        longitude: '-74.4041622',
        latitude: '40.0757384',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            }
        ]
    },
    {
        country: 'Michigan, USA',
        longitude: '-84.6824346',
        latitude: '43.6211955',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            }
        ]
    },
    {
        country: 'Ohio, USA',
        longitude: '-82.6881395',
        latitude: '40.2253569',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            }
        ]
    },
    {
        country: 'Quebec, Canada',
        longitude: '-71.8258668',
        latitude: '52.4760892',
        been: [
            {
                place: 'Boston - Canada',
                year: '2017'
            }
        ]
    },
    {
        country: 'Ontario, Canada',
        longitude: '-86.000977',
        latitude: '50.000678',
        been: [
            {
                place: 'Chicago - New-York - Washington',
                year: '2014'
            },
            {
                place: 'Boston - Canada',
                year: '2017'
            }
        ]
    },

];

const Wantpoints = [
    {
        country: 'Iran',
        longitude: '54.5643516',
        latitude: '32.6475314'
    },
    {
        country: 'Argentina',
        longitude: '-64.31646728515625',
        latitude: '-37.157466888427734'
    },
    {
        country: 'Iceland',
        longitude: '-18.1059013',
        latitude: '64.9841821'
    },
    {
        country: 'Washington State, USA',
        longitude: '-120.2126139',
        latitude: '47.2868352'
    },
    {
        country: 'British Columbia, Canada',
        longitude: '-125.002441',
        latitude: '55.001251'
    },
    {
        country: 'India',
        longitude: '78.6677428',
        latitude: '22.3511148'
    },
];

const UzbekistanRoute = [
    {
        location: "Tashkent" ,
        latitude: 41.3123363,
        longitude: 69.2787079,
    },
    {
        location: "Nukus" ,
        latitude: 42.4586038,
        longitude: 59.6058539,
    },
    {
        location: "Muynak" ,
        latitude: 43.7643276,
        longitude: 59.0297467,
    },
    {
        location: "Nukus" ,
        latitude: 42.4586038,
        longitude: 59.6058539,
    },
    {
        location: "Khiva" ,
        latitude: 41.3776893,
        longitude: 60.3620006,
    },
    {
        location: "Bukhara" ,
        latitude: 39.7675529,
        longitude: 64.4231326,
    },
    {
        location: "Samarkant" ,
        latitude: 39.6550017,
        longitude: 66.9756954,
    },
    {
        location: "Shahrisabz" ,
        latitude: 39.046163063181865,
        longitude: 66.83310246324571,
    },
    {
        location: "Samarkant" ,
        latitude: 39.6550017,
        longitude: 66.9756954,
    },
    {
        location: "Tashkent" ,
        latitude: 41.3123363,
        longitude: 69.2787079,
    },
    {
        location: "Kokand" ,
        latitude: 40.5361326,
        longitude: 70.9278983,
    },
    {
        location: "Fergana" ,
        latitude: 40.3791469,
        longitude: 71.7890479,
    },
    {
        location: "Tashkent" ,
        latitude: 41.3123363,
        longitude: 69.2787079,
    }
];

const JapanRoute = [
    {
        location: "Tokyo" ,
        latitude: 35.6828387,
        longitude: 139.7594549,
    },
    {
        location: "kyoto" ,
        latitude: 35.021041,
        longitude: 135.7556075,
    },
    {
        location: "Himeji" ,
        latitude: 34.8153529,
        longitude: 134.6854793,
    },
    {
        location: "Hiroshima" ,
        latitude: 34.3916058,
        longitude: 132.4518156,
    },
    {
        location: "Miyanjima" ,
        latitude: 34.271448,
        longitude: 132.3088722,
    },
    {
        location: "Hiroshima" ,
        latitude: 34.3916058,
        longitude: 132.4518156,
    },
    {
        location: "Nagasaki" ,
        latitude: 33.1154683,
        longitude: 129.7874339,
    },
    {
        location: "Hakata" ,
        latitude: 33.5900436,
        longitude: 130.4203556,
    },
    {
        location: "Kokura" ,
        latitude: 33.8865238,
        longitude: 130.8820724,
    },
    {
        location: "Nara" ,
        latitude: 34.2963089,
        longitude: 135.8816819,
    },
    {
        location: "Kanazawa" ,
        latitude: 36.5780499,
        longitude: 136.6480247,
    },
    {
        location: "Nagano" ,
        latitude: 36.1143945,
        longitude: 138.0319015,
    },
    {
        location: "Yudanaka" ,
        latitude: 36.7413439,
        longitude: 138.4148887,
    },
    {
        location: "Nikko" ,
        latitude: 36.7199292,
        longitude: 139.6983288,
    },
    {
        location: "Tokyo" ,
        latitude: 35.6828387,
        longitude: 139.7594549,
    }
];

const HolzgauMunchen = [
    {
        location: "Holzgau" ,
        latitude: 47.2600843,
        longitude: 10.3445814,
    },
    {
        location: "München" ,
        latitude: 48.1371079,
        longitude: 11.5753822,
    }
]

const Holzgau = [
    {
        location: "Holzgau" ,
        latitude: 47.2600843,
        longitude: 10.3445814,
    }
]

const RheinRoute = [
    {
        location: "Koblenz" ,
        latitude: 50.3533278,
        longitude: 7.5943951,
    },
    {
        location: "Sankt Goar" ,
        latitude: 50.15,
        longitude: 7.71667,
    },
    {
        location: "Oberwesel" ,
        latitude: 50.1075,
        longitude: 7.7266193,
    },
    {
        location: "Bingen am Rhein" ,
        latitude: 49.9667,
        longitude: 7.9,
    }
]

const UsaCanRoute = [
    {
        location: "Boston" ,
        latitude: 42.3602534,
        longitude: -71.0582912,
    },
    {
        location: "Saranac Lake" ,
        latitude: 44.329497,
        longitude: -74.131279,
    },
    {
        location: "Montreal" ,
        latitude: 45.4972159,
        longitude: -73.6103642,
    },
    {
        location: "Quebec" ,
        latitude: 46.8259601,
        longitude: -71.2352226,
    },
    {
        location: "Mount Washington" ,
        latitude: 44.2700827508432,
        longitude: -71.30269995156361,
    },
    {
        location: "Boston" ,
        latitude: 42.3602534,
        longitude: -71.0582912,
    }
]

const Paris = [
    {
        location: "Paris" ,
        latitude: 48.8566969,
        longitude: 2.3514616,
    }
]

const ToulouseRegion = [
    {
        location: "Plage" ,
        latitude: 43.2798298,
        longitude: 3.3616164,
    },
    {
        location: "Toulouse" ,
        latitude: 43.6044622,
        longitude: 1.4442469,
    },
    {
        location: "Andorra" ,
        latitude: 42.5407167,
        longitude: 1.5732033,
    }
]

export {Beenpoints, Wantpoints, UzbekistanRoute, JapanRoute, HolzgauMunchen, Holzgau, RheinRoute, UsaCanRoute, Paris, ToulouseRegion}
import {HomePage} from "../content/HomePage";
import {TravelStartPage} from "../content/TravelStartPage";
import {PhotoStartPage} from "../content/PhotoStartPage";
import {ProjectStartPage} from "../content/ProjectStartPage";
import {InfoStartPage} from "../content/InfoStartPage";
import {Hobbies} from "../content/Hobbies";
import {Education} from "../content/Education";
import {MakerProjects} from "../content/MakerProjects";
import {OtherProjects} from "../content/OtherProjects";
import {Wintertime} from "../content/Wintertime";
import {Website} from "../content/website";

import {
    Molen,
    Schelde,
    Zwin,
    Uzbekistan19,
    Japan18,
    Holzgau19,
    Holzgau18,
    Rhein20,
    UsaCan17,
    Paris18,
    ToulouseAndorra
} from "./Images";
import {
    Holzgau,
    HolzgauMunchen,
    JapanRoute,
    Paris,
    RheinRoute,
    ToulouseRegion,
    UsaCanRoute,
    UzbekistanRoute
} from "./MapPoints";

const MenuItems = [
    {
        name: 'Menu',
        label: 'Home',
        iconleft: './angle-left.svg',
        iconright: './angle-right.svg',
        startpage: HomePage,
        labels: [
            { },
        ]
    },
    {
        name: 'TravelMenu',
        label: 'Travel',
        icon: "./plane.svg",
        startpage: TravelStartPage,
        labels: [
            {
                name: 'Mittelrhein20',
                label: 'Mittelrhein 2020',
                site: 'travelPage',
                title: "Mittelrhein 2020",
                collection: Rhein20,
                route: RheinRoute,
                center: [7.7266193, 50.1075],
                text: "Summer holiday of 2020"
            },
            {
                name: 'Uzbekistan19',
                label: 'Uzbekistan 2019',
                site: 'travelPage',
                title: "Uzbekistan 2019",
                collection: Uzbekistan19,
                route: UzbekistanRoute,
                center: [63.9528098, 41.32373],
                text: "Summer holiday of 2019"
            },
            {
                name: 'Holzgau19',
                label: 'Holzgau 2019',
                site: 'travelPage',
                title: "Holzgau and München 2019",
                collection: Holzgau19,
                route: HolzgauMunchen,
                center: [11.073966693994652, 47.57501779110968],
                text: "Trip visiting München and hiking in Holzgau and local area"
            },
            {
                name: 'Toulouse18-19',
                label: 'Toulouse 18-19',
                site: 'travelPage',
                title: "Toulouse and Region 2018-2019",
                collection: ToulouseAndorra,
                route: ToulouseRegion,
                center: [1.4442469, 43.6044622],
                text: "Combination of multiple small trips to Toulouse and region"
            },
            {
                name: 'Japan18',
                label: 'Japan 2018',
                site: 'travelPage',
                title: "Japan 2018",
                collection: Japan18,
                route: JapanRoute,
                center: [139.2394179, 36.5748441],
                text: "Summer holiday of 2018"
            },
            {
                name: 'Holzgau18',
                label: 'Holzgau 2018',
                site: 'travelPage',
                title: "Holzgau 2018",
                collection: Holzgau18,
                route: Holzgau,
                center: [10.3445814, 47.2600843],
                text: "Family reunion in Holzgau"
            },
            {
                name: 'Paris18',
                label: 'Paris 2018',
                site: 'travelPage',
                title: "Paris 2018",
                collection: Paris18,
                route: Paris,
                center: [2.3514616, 48.8566969],
                text: "City trip to Paris"
            },
            {
                name: 'usa17',
                label: 'USA/Canada 2017',
                site: 'travelPage',
                title: "USA/Canada 2017",
                collection: UsaCan17,
                route: UsaCanRoute,
                center: [-71.30269995156361, 44.2700827508432],
                text: "Trip with my dad to Boston, Montréal, Quebec and everything in between"
            },
            {
                name: 'France17',
                label: 'France 2017',
                site: 'france17'
            },
            {
                name: 'Hungary16',
                label: 'Hungary 2016',
                site: 'Hungary16'
            },
            {
                name: 'Sicily15',
                label: 'Sicily 2015',
                site: 'Sicily15'
            }
        ]
    },
    {
        name: 'PhotoMenu',
        label: 'Photography',
        icon: './camera.svg',
        startpage: PhotoStartPage,
        labels: [
            {
                name: 'Zwin',
                label: 'Zwin',
                site: 'picturePage',
                title: "Zwin, Belgian Coast",
                collection: Zwin,
                text: "My granddad en I went on a little trip to the Belgian coast to visit a nature park.\n(September 2016)"

            },
            {
                name: 'Schelde',
                label: 'Schelde',
                site: 'picturePage',
                title: "Schelde",
                collection: Schelde,
                text: "On one of my bike sessions, I decided to bring my camera with me. Here are some of the pictures I took.\n(November 2015)"

            },
            {
                name: 'Mills',
                label: 'Mills',
                site: 'picturePage',
                title: "Flemish Mills",
                collection: Molen,
                text: "There is one day a year when some monuments open their doors for visitors. With my family, we went on a 40 km bike trip to see a windmill in Zingem and a watermill in Zwalm, in the Flemish Ardennes.\n (September 2016)"
            },
        ]
    },
    {
        name: 'ProjectMenu',
        label: 'Projects',
        icon: './code.svg',
        startpage: ProjectStartPage,
        labels: [
            {name: 'MakerProjects', label: 'Maker Projects', site: MakerProjects},
            {name: 'Website', label: 'Websites', site: Website},
            {name: 'BookApp', label: 'Book Application', site: 'BookApp'},
            {name: 'OtherProjects', label: 'Other Projects', site: OtherProjects},
        ]
    },
    {
        name: 'InfoMenu',
        label: 'Info',
        icon: './info.svg',
        startpage: InfoStartPage,
        labels: [
            {name: 'Education', label: 'Education', site: Education},
            {name: 'Hobbies', label: 'Hobbies', site: Hobbies},
            {name: 'Wintertime', label: 'Winter', site: Wintertime}
            //{name: 'Contact', label: 'Contact', site: Contact}
        ]
    }

];

export {MenuItems}

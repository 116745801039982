import React, {Component} from "react";

export class PhotoStartPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            collection: "PhotoStart"
        }
    }

    render(){
        return(
            <div className="photopage">
                <h1>Photography</h1>
                <p>A gallery with some of my pictures</p>
                <p>More to come soon ...</p>
            </div>
        );
    }
}

//<Gallery collection={this.state.collection}/>
import React, {Component} from "react";

export class MakerProjects extends Component {
    render(){
        return(
            <div className="makerprojectpage">
                <div className="makerproject">
                    <h1>Raspberry Pi</h1>
                    <img src="/icons/raspberry-pi.svg" alt="icon" className="icon"/>
                    <img src="/photos/projects/PI.jpg" alt="raspberrypi"/>
                    <img src="/photos/projects/RaspberrySensors.jpg" alt="raspberrypi"/>
                    <p>My own flightRadar with dump1090</p>
                    <img src="/photos/projects/Dump1090.jpg" alt="Dump1090"/>
                </div>
                <div className="makerproject">
                    <h1>NodeMcu</h1>
                    <img src="/icons/microchip.svg" alt="icon" className="icon"/>
                    <img src="/photos/projects/NodeMCU.jpg" alt="NodeMCU"/>
                    <p>Depth sensor for the rainwater well, to measure the volume of water</p>
                    <img src="/photos/projects/Waterput2.jpg" alt="waterput"/>
                    <img src="/photos/projects/Waterput1.jpg" alt="waterput"/>
                </div>
            </div>
        );
    }
}
import React, {Component} from "react";
import { Chrono } from "react-chrono";

export class Wintertime extends Component {
    render() {
        return (
            <div style={{ width: "100%", height: "90vh" }}>
                    <Chrono mode="VERTICAL_ALTERNATING" cardHeight={200} scrollable theme={{primary: "black", secondary: "grey", cardBgColor: "lightgrey", cardForeColor: "grey" }}>
                        <div className="timestamp">
                            <h2>1999</h2>
                            <h1>First snow</h1>
                            <img src="/photos/winter/winter013.jpg" alt="firstsnow"/>
                            <img src="/photos/winter/winter014.jpg" alt="firstsnow"/>
                        </div>
                        <div className="timestamp">
                            <h2>2000</h2>
                            <h1>First time skiing</h1>
                            <img src="/photos/winter/winter012.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2002</h2>
                            <h1>Skiing</h1>
                            <img src="/photos/winter/winter001.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2003</h2>
                            <h1>Drink break</h1>
                            <img src="/photos/winter/winter002.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2004</h2>
                            <h1>Freezing weather</h1>
                            <img src="/photos/winter/winter003.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2005</h2>
                            <h1>Skischool</h1>
                            <img src="/photos/winter/winter004.jpg" alt="skiing"/>
                            <img src="/photos/winter/winter005.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2007</h2>
                            <h1>First offpiste slope</h1>
                            <img src="/photos/winter/winter006.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2007</h2>
                            <h1>Learning to Snowboard</h1>
                            <img src="/photos/winter/winter007.jpg" alt="snowboarding"/>
                            <img src="/photos/winter/winter008.jpg" alt="snowboarding"/>
                        </div>
                        <div className="timestamp">
                            <h2>2008</h2>
                            <h1>Snow playtime</h1>
                            <img src="/photos/winter/winter009.jpg" alt="snow"/>
                        </div>
                        <div className="timestamp">
                            <h2>2010</h2>
                            <h1>Skiing with my sister</h1>
                            <img src="/photos/winter/winter010.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2012</h2>
                            <h1>Snowboarding during Autumbreak</h1>
                            <p>First snow of the season</p>
                            <img src="/photos/winter/winter016.jpg" alt="snowboarding"/>
                            <img src="/photos/winter/winter018.jpg" alt="snowboarding"/>
                        </div>
                        <div className="timestamp">
                            <h2>2013</h2>
                            <h1>Teaching at the skischool</h1>
                            <p>ESI Arc-en-Ciel Siviez</p>
                            <img src="/photos/winter/winter020.jpg" alt="skischool"/>

                            <ul className="SocialListSmall">
                                <li>
                                    <a href="https://arcenciel-siviez.ch" target="_blank" rel="noopener noreferrer">
                                        <img src="/icons/Social/address-book.svg" alt="site" title="site"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/ecoledeskinendaz/" target="_blank" rel="noopener noreferrer">
                                        <img src="/icons/Social/facebook.svg" alt="Facebook" title="Facebook"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/esiarcenciel/" target="_blank" rel="noopener noreferrer">
                                        <img src="/icons/Social/instagram.svg" alt="Instagram" title="Instagram"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="timestamp">
                            <h2>2014</h2>
                            <h1>Still teaching</h1>
                            <img src="/photos/winter/winter021.jpg" alt="skischool"/>
                            <img src="/photos/winter/winter023.jpg" alt="skischool"/>
                            <img src="/photos/winter/winter011.jpg" alt="skischool"/>
                        </div>
                        <div className="timestamp">
                            <h2>2015</h2>
                            <h1>Best skischool</h1>
                            <img src="/photos/winter/winter024.jpg" alt="skischool"/>
                            <img src="/photos/winter/winter026.jpg" alt="skischool"/>
                        </div>
                        <div className="timestamp">
                            <h2>2016</h2>
                            <h1>Little competition</h1>
                            <img src="/photos/winter/winter027.jpg" alt="skirace"/>
                        </div>
                        <div className="timestamp">
                            <h2>2017</h2>
                            <h1>Snowboarding in France</h1>
                            <img src="/photos/winter/winter037.jpg" alt="skiingF"/>
                            <img src="/photos/winter/winter036.jpg" alt="skiingF"/>
                            <img src="/photos/winter/winter035.jpg" alt="skiingF"/>
                        </div>
                        <div className="timestamp">
                            <h2>2018</h2>
                            <h1>Snowboarding</h1>
                            <img src="/photos/winter/winter028.jpg" alt="snowboarding"/>
                            <img src="/photos/winter/winter030.jpg" alt="snowboarding"/>
                        </div>
                        <div className="timestamp">
                            <h2>2018 and 2019</h2>
                            <h1>Hiking and skiing in Andorra</h1>
                            <img src="/photos/winter/winter038.jpg" alt="andorra"/>
                            <img src="/photos/winter/winter039.jpg" alt="andorra"/>
                        </div>
                        <div className="timestamp">
                            <h2>2019</h2>
                            <h1>Snowboarding or skiing ???</h1>
                            <img src="/photos/winter/winter033.jpg" alt="skiing"/>
                            <img src="/photos/winter/winter031.jpg" alt="skiing"/>
                            <img src="/photos/winter/winter032.jpg" alt="skiing"/>
                        </div>
                        <div className="timestamp">
                            <h2>2020</h2>
                            <h1>Skiing</h1>
                            <img src="/photos/winter/winter034.jpg" alt="skiing"/>
                        </div>
                    </Chrono>
            </div>
        );
    }
}

import React, {Component} from "react";
import Gallery from "react-photo-gallery";

export class GalleryTemplate extends Component {
    render() {
        return (
            <div>
                <h1>{this.props.title}</h1>
                <p>{this.props.text}</p>
                <Gallery photos={this.props.collection} limitNodeSearch={3} />
            </div>
        )
    }
}

import React, {Component} from "react";
import {Chrono} from "react-chrono";

export class Education extends Component {
    render() {
        return (
            <div style={{ width: "100%", height: "90vh" }}>
                <Chrono mode="VERTICAL_ALTERNATING" cardHeight={200} scrollable theme={{primary: "black", secondary: "grey", cardBgColor: "lightgrey", cardForeColor: "grey" }}>
                    <div className="timestamp">
                        <h2>2016-2021</h2>
                        <h1>UGent</h1>
                        <p>Gent University</p>
                        <p>Bachelor and Master of Science in Information Engineering Technology </p>
                        <img src="/photos/info/Infopage012.jpg" alt="Theepot"/>
                    </div>

                    <div className="timestamp">
                        <h2>2009-2015</h2>
                        <h1>Sint-Barbara College Gent</h1>
                        <p>Secundary Education</p>
                        <p>Math-Science</p>
                        <img src="/photos/info/Infopage010.jpg" alt="SBC"/>
                    </div>
                    <div className="timestamp">
                        <h2>2003-2009</h2>
                        <h1>VBS EKE</h1>
                        <p>Primary School</p>
                        <img src="/photos/info/Infopage008.jpg" alt="primarySchool"/>
                    </div>
                    <div className="timestamp">
                        <h2>2000-2003</h2>
                        <h1>VBS Eke</h1>
                        <p>Preschool</p>
                        <img src="/photos/info/Infopage011.jpg" alt="preschool"/>
                    </div>
                </Chrono>
            </div>
        );
    }
}
import React, {Component} from "react";

export class HomePage extends Component {
    render(){
        return(
            <div className="homepage">
                <h1>Welcome to Liesbeth's pages!</h1>
                <p>The code is more impressive than the looks.</p>
                <p>Still under construction.</p>
            </div>
        );
    }
}
import {Component} from "react";
import React from "react";

export class SocialList extends Component {
    render(){
        return(
            <ul className="SocialList">
                <li>
                    <a href="https://www.facebook.com/lbogaert97" target="_blank" rel="noopener noreferrer">
                        <img src="icons/Social/facebook.svg" alt="Facebook" title="Facebook"/>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/b_liesbeth97/" target="_blank" rel="noopener noreferrer">
                        <img src="icons/Social/instagram.svg" alt="Instagram" title="Instagram"/>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/b_liesbeth97" target="_blank" rel="noopener noreferrer">
                        <img src="icons/Social/twitter.svg" alt="Twitter" title="Twitter"/>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/lbogaert97" target="_blank" rel="noopener noreferrer">
                        <img src="icons/Social/linkedin.svg" alt="LinkedIn" title="LinkedIn"/>
                    </a>
                </li>
                <li>
                    <a href="https://www.goodreads.com/lbogaert97" target="_blank" rel="noopener noreferrer">
                        <img src="icons/Social/goodreads.svg" alt="Goodreads" title="Goodreads"/>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/BLiesbeth" target="_blank" rel="noopener noreferrer">
                        <img src="icons/Social/github.svg" alt="Github" title="Github"/>
                    </a>
                </li>
            </ul>
        )
    }
}
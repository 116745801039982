import React, {Component} from "react";

export class Hobbies extends Component {
    render() {
        return (
            <div className="hobbies">
                <div>
                    <p>Flying</p>
                    <img src="/photos/info/Infopage006.jpg" alt="Flying"/>
                </div>
                <div>
                    <p>Skiing, Snowboarding, Ice skating</p>
                    <img src="/photos/info/Infopage003.jpg" alt="Skiing"/>
                </div>
                <div>
                    <p>Photography</p>
                    <img src="/photos/info/Infopage004.jpg" alt="Photography"/>
                </div>
                <div>
                    <p>Ninjutsu self-defense</p>
                    <img src="/photos/info/Infopage002.jpg" alt="Ninjutsu"/>
                </div>
                <div>
                    <p>Software and Electronics</p>
                    <img src="/photos/info/Infopage001.jpg" alt="pi"/>
                </div>
                <div>
                    <p>Reading</p>
                    <img src="/photos/info/Infopage005.jpg" alt="Reading"/>
                </div>

            </div>
        );
    }
}

/*
<table >
                    <tabel>
                    <tr>
                        <td>Probably the most important thing to know. Everything in my life has something to do with
                            flying. I love planes. I love being high in the sky. The farther away from the ground, the
                            better. Actually I do not yet own a pilot license, but I can’t wait to start a flying
                            course.
                        </td>
                    </tr>
                    <tr>
                        <td>Another very important part of my life is sports. My all-time favorites are winter sports. I
                            practice skiing since a young age and started snowboarding so I could alternate a little
                            bit. I try to visit the mountains as often as possible. Being surrounded by mountains gives
                            me the feeling of being on the top of the world. And the speed feels a little like freedom.
                            Also don’t ask me the question which sport I prefer, I will never be able to make a
                            decision.
                        </td>
                    </tr>
                    <tr>
                        <td>But sadly enough there is not always snow and time to go skiing. So during the year I
                            practice self-defense. At primary school I started judo. But I switched to ninjutsu when I
                            was 12 years. Most people don’t really know this sport is. Just trying to be able to defend
                            myself and some ninja skills of course :p
                        </td>

                    </tr>
                    <tr>
                        <td>One of my hobbies is photography, I love capturing adventures, holiday trips, my crazy
                            sister, some nature, … Almost everything that wants to be shot with my camera.
                        </td>
                    </tr>
                    <tr>
                        <td>Another thing I love is working with electronics. I got a raspberry pi with a bunch of
                            sensor to play around with. But I also like working on my computer, the best example would
                            be this website.
                        </td>
                    </tr>

                </table>

 */
import React, {Component} from "react";

export class ProjectStartPage extends Component {
    render(){
        return(
            <div className="projectpage">
                <h1>Projects</h1>
            </div>
        );
    }
}